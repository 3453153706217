import React, { useState } from "react";

import caretDownOutline from "../../../assets/caret-down-outline-1.svg";

const Menu = ({ data, setData }) => {
  const [order, setOrder] = useState("ASC");

  const sort = (type) => {
    let sortedArray = [...data];

    if (order === "ASC") {
      sortedArray.sort((a, b) =>
        type !== "totalValue"
          ? a[type].localeCompare(b[type])
          : a[type] - b[type]
      );
      setOrder("DSC");
    } else {
      sortedArray.sort((a, b) =>
        type !== "totalValue"
          ? b[type].localeCompare(a[type])
          : b[type] - a[type]
      );
      setOrder("ASC");
    }
    setData(sortedArray);
  };

  return (
    <>
      <thead>
        <tr className="menu-row">
          <th onClick={() => sort("name")}>
            Candidato
            <img alt="icon-arrow" src={caretDownOutline} />
          </th>
          <th onClick={() => sort("sigle")}>
            Partido
            <img alt="icon-arrow" src={caretDownOutline} />
          </th>
          <th onClick={() => sort("position")}>
            Cargo
            <img alt="icon-arrow" src={caretDownOutline} />
          </th>
          <th onClick={() => sort("state")}>
            Estado
            <img alt="icon-arrow" src={caretDownOutline} />
          </th>
          <th onClick={() => sort("job")}>
            Ocupação
            <img alt="icon-arrow" src={caretDownOutline} />
          </th>
          <th onClick={() => sort("assetsDesc")}>
            Bens
            <img alt="icon-arrow" src={caretDownOutline} />
          </th>
          <th onClick={() => sort("totalValue")}>
            Valor total
            <img alt="icon-arrow" src={caretDownOutline} />
          </th>
        </tr>
      </thead>
    </>
  );
};

export default Menu;