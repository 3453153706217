import React from "react";

import Logo from "../../assets/logo-mobile.png";

const Header2024 = () => {
  return (
    <>
      <section className="header-container">
        <a
          href="https://paradigma.education/sobre-nos"
          className="header-logo"
        >
          <img src={Logo} alt="logo paradigma" />
        </a>
        <div className="header-title">
          <h1>Quais políticos têm cripto?</h1>
          <div className="header-subtitle">
            <p>
              Dados da{" "}
              <a href="https://divulgacandcontas.tse.jus.br/divulga/#/">
                declaração de bens
              </a>{" "}
              ao TSE
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header2024;
