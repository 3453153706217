import React from "react";
import CryptoPoliticos from "../components/crypto-politicos/crypto2022";

const Version2022 = () => {
  return (
    <CryptoPoliticos />
  );
};

export default Version2022;
