import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div>
        <Link to="/2022">2022</Link> | <Link to="/">2024</Link>
      </div>
    </footer>
  );
};

export default Footer;
