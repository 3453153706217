import React, { useState, useEffect } from "react";
import axios from "axios";
import Marquee from "react-fast-marquee";

const CryptoMarquee = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const API_URL = "https://api.paradigma.education/api/v2";
    axios
      .get(`${API_URL}/candidates?page=1`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <Marquee gradient={false} speed={50} autoFill={true}>
      <div className="marquee-item">
        🗺️ Candidatos mapeados:
        <span className="info-bg">
          {(data.totalCandidates || 0).toLocaleString()}
        </span>
      </div>

      <div className="marquee-item">
        🤑 Candidatos com cripto:
        <span className="info-bg">
          {(data.candidatesCrypto || 0).toLocaleString()}
        </span>
        {` (${(data.percentageCripto || 0).toFixed(2)}%)`}
      </div>

      <div className="marquee-item">
        💰 Valor Holdado:
        <span className="info-bg">
          R$ {(data.valorHoldado || 0).toLocaleString("pt-br")}
        </span>
      </div>

      <div className="marquee-item">
        🏴 Partido mais Coiner:
        <span className="info-bg">{data.coinerParty || "-"}</span>
      </div>

      <div className="marquee-item">
        ⏰ Última atualização:
        <span className="info-bg">
          {( new Date(data.ultimaAtualizacao).toLocaleDateString("pt-br") || "-" )}
        </span>
      </div>
    </Marquee>
  );
};

export default CryptoMarquee;
